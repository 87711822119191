import { CompanyInfo } from '~/models/types';

const defaultValue: CompanyInfo = {
  name: 'サンプル事業者',
  transferNameForLoan: 'ｲﾗｲﾆﾝﾒｲ',
  corporateNumber: '1234567890123',
  tradeName: '',
  selfEmployed: false,
  postalCode: '1710031',
  address: '東京都 豊島区',
};

const selfEmployed: CompanyInfo = {
  ...defaultValue,
  selfEmployed: true,
};

export const companyInfo = (_pattern: number): CompanyInfo => defaultValue;

companyInfo.default = defaultValue;
companyInfo.selfEmployed = selfEmployed;
