import { BusinessFactCheck, BusinessFactCheckEvidence } from '~/models/types';

export const businessFactCheckEvidence: BusinessFactCheckEvidence = {
  businessFactCheckEvidenceId: 0,
  url: 'https://placehold.jp/800x1200.png',
  status: 'prepared',
  type: {
    masterBusinessFactCheckEvidenceTypeId: 1,
    label: '開業届',
    others: false,
  },
  rejectReasons: [
    {
      businessFactCheckEvidenceRejectReasonId: 1,
      typeRejectReasonType: {
        masterBusinessFactCheckEvidenceTypeRejectReasonTypeId: 1,
        nextActionText: 'ご登録いただいた住所を書類の「納税地」欄に記載の内容へ変更してください。',
        nextActionType: 'editCompany',
        rejectReasonType: {
          masterBusinessFactCheckEvidenceRejectReasonTypeId: 1,
          requiredCustomerSupportContact: true,
          label: '住所が不一致',
          others: false,
        },
      },
      rejectReasonText: null,
    },
    {
      businessFactCheckEvidenceRejectReasonId: 2,
      typeRejectReasonType: {
        masterBusinessFactCheckEvidenceTypeRejectReasonTypeId: 2,
        nextActionText: '企業名が読み取れる書類を再アップロードしてください。',
        nextActionType: 'reUploadFile',
        rejectReasonType: {
          masterBusinessFactCheckEvidenceRejectReasonTypeId: 2,
          requiredCustomerSupportContact: true,
          label: '書類の企業名が読み取れない',
          others: false,
        },
      },
      rejectReasonText: null,
    },
    {
      businessFactCheckEvidenceRejectReasonId: 3,
      typeRejectReasonType: {
        masterBusinessFactCheckEvidenceTypeRejectReasonTypeId: 3,
        nextActionText: null,
        nextActionType: null,
        rejectReasonType: {
          masterBusinessFactCheckEvidenceRejectReasonTypeId: 3,
          requiredCustomerSupportContact: true,
          label: 'その他',
          others: true,
        },
      },
      rejectReasonText: 'その他のテキスト、その他のテキスト',
    },
  ],
};

const defaultValue: BusinessFactCheck = {
  businessFactCheckId: 1,
  status: 'rejected',
  evidence: businessFactCheckEvidence,
};

const editCompany: BusinessFactCheck = {
  businessFactCheckId: 1,
  status: 'rejected',
  evidence: {
    ...businessFactCheckEvidence,
    rejectReasons: businessFactCheckEvidence.rejectReasons.filter(
      (reason) => reason.typeRejectReasonType.nextActionType === 'editCompany'
    ),
  },
};

const others: BusinessFactCheck = {
  businessFactCheckId: 1,
  status: 'rejected',
  evidence: {
    ...businessFactCheckEvidence,
    rejectReasons: businessFactCheckEvidence.rejectReasons.filter(
      (reason) => reason.typeRejectReasonType.nextActionType === null
    ),
  },
};

export const businessFactCheck = (_pattern: number): BusinessFactCheck => defaultValue;

businessFactCheck.default = defaultValue;
businessFactCheck.editCompany = editCompany;
businessFactCheck.others = others;
