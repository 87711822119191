/* eslint-disable */
import { AspidaClient, BasicHeaders } from 'aspida'
import { Methods as Methods0 } from './register/api/v1/master/company/industries'
import { Methods as Methods1 } from './register/api/v1/master/company/issues'
import { Methods as Methods2 } from './register/api/v1/master/company/occupation_categories'
import { Methods as Methods3 } from './register/api/v1/master/company/occupations'
import { Methods as Methods4 } from './register/api/v1/master/company/part_time_max_count_per_days'
import { Methods as Methods5 } from './register/api/v1/self/web_entries'
import { Methods as Methods6 } from './register/api/v1/web_entries/_token@string'
import { Methods as Methods7 } from './register/api/v1/web_entries/_token@string/clients'
import { Methods as Methods8 } from './register/api/v1/web_entries/_token@string/company'
import { Methods as Methods9 } from './register/api/v1/web_entries/_token@string/company/business_fact_check'
import { Methods as Methods10 } from './register/api/v1/web_entries/_token@string/company/verification'
import { Methods as Methods11 } from './register/api/v1/web_entries/_token@string/refresh'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/register/api/v1/master/company/industries'
  const PATH1 = '/register/api/v1/master/company/issues'
  const PATH2 = '/register/api/v1/master/company/occupation_categories'
  const PATH3 = '/register/api/v1/master/company/occupations'
  const PATH4 = '/register/api/v1/master/company/part_time_max_count_per_days'
  const PATH5 = '/register/api/v1/self/web_entries'
  const PATH6 = '/register/api/v1/web_entries'
  const PATH7 = '/clients'
  const PATH8 = '/company'
  const PATH9 = '/company/business_fact_check'
  const PATH10 = '/company/verification'
  const PATH11 = '/refresh'
  const GET = 'GET'
  const POST = 'POST'
  const PATCH = 'PATCH'

  return {
    register: {
      api: {
        v1: {
          master: {
            company: {
              industries: {
                get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
                $get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH0}`
              },
              issues: {
                get: (option?: { headers?: Methods1['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json(),
                $get: (option?: { headers?: Methods1['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH1}`
              },
              occupation_categories: {
                get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
                $get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH2}`
              },
              occupations: {
                get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH3, GET, option).json(),
                $get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH3}`
              },
              part_time_max_count_per_days: {
                get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json(),
                $get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH4}`
              }
            }
          },
          self: {
            web_entries: {
              post: (option: { body: Methods5['post']['reqBody'], headers?: Methods5['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods5['post']['status']>(prefix, PATH5, POST, option).send(),
              $post: (option: { body: Methods5['post']['reqBody'], headers?: Methods5['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods5['post']['status']>(prefix, PATH5, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH5}`
            }
          },
          web_entries: {
            _token: (val0: string) => {
              const prefix0 = `${PATH6}/${val0}`

              return {
                clients: {
                  post: (option: { body: Methods7['post']['reqBody'], headers?: Methods7['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods7['post']['status']>(prefix, `${prefix0}${PATH7}`, POST, option).send(),
                  $post: (option: { body: Methods7['post']['reqBody'], headers?: Methods7['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods7['post']['status']>(prefix, `${prefix0}${PATH7}`, POST, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix0}${PATH7}`
                },
                company: {
                  business_fact_check: {
                    post: (option: { body: Methods9['post']['reqBody'], headers?: Methods9['post']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods9['post']['status']>(prefix, `${prefix0}${PATH9}`, POST, option).send(),
                    $post: (option: { body: Methods9['post']['reqBody'], headers?: Methods9['post']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods9['post']['status']>(prefix, `${prefix0}${PATH9}`, POST, option).send().then(r => r.body),
                    get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, `${prefix0}${PATH9}`, GET, option).json(),
                    $get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, `${prefix0}${PATH9}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix0}${PATH9}`
                  },
                  verification: {
                    post: (option: { body: Methods10['post']['reqBody'], headers?: Methods10['post']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods10['post']['status']>(prefix, `${prefix0}${PATH10}`, POST, option).send(),
                    $post: (option: { body: Methods10['post']['reqBody'], headers?: Methods10['post']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods10['post']['status']>(prefix, `${prefix0}${PATH10}`, POST, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix0}${PATH10}`
                  },
                  post: (option: { body: Methods8['post']['reqBody'], headers?: Methods8['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods8['post']['status']>(prefix, `${prefix0}${PATH8}`, POST, option).send(),
                  $post: (option: { body: Methods8['post']['reqBody'], headers?: Methods8['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods8['post']['status']>(prefix, `${prefix0}${PATH8}`, POST, option).send().then(r => r.body),
                  get: (option?: { headers?: Methods8['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, `${prefix0}${PATH8}`, GET, option).json(),
                  $get: (option?: { headers?: Methods8['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, `${prefix0}${PATH8}`, GET, option).json().then(r => r.body),
                  patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, `${prefix0}${PATH8}`, PATCH, option).send(),
                  $patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, `${prefix0}${PATH8}`, PATCH, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix0}${PATH8}`
                },
                refresh: {
                  patch: (option?: { headers?: Methods11['patch']['reqHeaders'], config?: T }) =>
                    fetch<Methods11['patch']['resBody'], BasicHeaders, Methods11['patch']['status']>(prefix, `${prefix0}${PATH11}`, PATCH, option).json(),
                  $patch: (option?: { headers?: Methods11['patch']['reqHeaders'], config?: T }) =>
                    fetch<Methods11['patch']['resBody'], BasicHeaders, Methods11['patch']['status']>(prefix, `${prefix0}${PATH11}`, PATCH, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix0}${PATH11}`
                },
                get: (option?: { headers?: Methods6['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, prefix0, GET, option).json(),
                $get: (option?: { headers?: Methods6['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, prefix0, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix0}`
              }
            }
          }
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
