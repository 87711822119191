import { randomWait } from '~/utils/randomWait';
import { IRegisterApiClient } from './index.type';
import { currentTokenStatus } from '~/models/mocks/currentTokenStatus';
import { companyPartTimeMaxCountPerDayOption } from '~/models/mocks/companyPartTimeMaxCountPerDayOption';
import { companyIndustryOption } from '~/models/mocks/companyIndustryOption';
import { companyIssueOption } from '~/models/mocks/companyIssueOption';
import { companyOccupationCategory } from '~/models/mocks/companyOccupationCategory';
import { businessFactCheck } from '~/models/mocks/businessFactCheck';
import { companyInfo } from '~/models/mocks/companyInfo';

export const registerMockApiClient: IRegisterApiClient = {
  createNewTokenForSelfWorkflow: async () => {
    await randomWait();
    return null;
  },
  registerCompanyVerification: async () => {
    await randomWait();
    return null;
  },
  fetchCurrentTokenStatus: async () => {
    await randomWait();
    return currentTokenStatus.default;
  },
  registerCompanyAndAccountInfo: async () => {
    await randomWait();
    return null;
  },
  uploadBusinessFactCheckFile: async () => {
    await randomWait();
    return null;
  },
  fetchBusinessFactCheck: async () => {
    await randomWait();
    return businessFactCheck.default;
  },
  updateCompanyInfo: async () => {
    await randomWait();
    return null;
  },
  fetchCompanyInfo: async () => {
    await randomWait();
    return companyInfo.default;
  },
  registerClientInfo: async () => {
    await randomWait();
    return null;
  },
  fetchMasterCompanyIssueOptions: async () => {
    await randomWait();
    return companyIssueOption.all;
  },
  fetchMasterCompanyOccupationCategories: async () => {
    await randomWait();
    return companyOccupationCategory.all;
  },
  fetchMasterCompanyPartTimeMaxCountPerDayOptions: async () => {
    await randomWait();
    return companyPartTimeMaxCountPerDayOption.all;
  },
  fetchMasterCompanyIndustryOptions: async () => {
    await randomWait();
    return companyIndustryOption.all;
  },
  refreshToken: async () => {
    await randomWait();
    return currentTokenStatus.default;
  },
};
